import { graphql, useStaticQuery } from 'gatsby';
import { Feature } from '../components/feature-section';


export const featuresQuery = (): Feature[] => {
  const { allFeaturesYaml } = useStaticQuery(
    graphql`
        query {
          allFeaturesYaml {
            edges {
              node {
                id
                icon {
                  publicURL
                  internal {
                    content
                  }
                }
                title
                description
              }
            }
          }
        }
      `,
  );

  return allFeaturesYaml.edges.map((edge) => edge.node);
};
