import * as React from 'react';
import styled from '../styles/styled';
import { FeatureIcon } from './feature-icon';
import { Feature } from './feature-section';


interface Props {
  icon: Feature['icon'];
  title: string;
  description: string;
}


export const FeatureBox: React.FunctionComponent<Props> = (props) => {
  return (
    <Container>
      <FeatureIcon icon={props.icon}/>
      <h4>{props.title}</h4>
      <div dangerouslySetInnerHTML={{ __html: props.description }}/>
    </Container>
  );
};


const Container = styled.div`

  & p {
    color: ${(props) => props.theme.colors.secondary.normal};
  }

  & h4 {
    color: ${(props) => props.theme.colors.secondary.dark};
  }
`;
