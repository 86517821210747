import { graphql, useStaticQuery } from 'gatsby';
import { Feature } from '../components/feature-section';
import { Step } from '../components/how-it-works-section';


export const howItWorksStepsQuery = (): Step[] => {
  const { allHowItWorksStepsYaml } = useStaticQuery(
    graphql`
        query {
          allHowItWorksStepsYaml(sort: {order: ASC, fields: idx}) {
            edges {
              node {
                id
                idx
                banner {
                  image {
                    publicURL
                  }
                  position
                }
                title
                description
                details {
                  icon {
                    publicURL
                    internal {
                      content
                      mediaType
                    }
                  }
                  heading
                  body
                }
                connector {
                  publicURL
                }
              }
            }
          }
        }
      `,
  );

  return allHowItWorksStepsYaml.edges.map((edge) => edge.node);
};
