import * as React from 'react';
import { ColoredPrimary } from '../styles/utils';
import { StandardSection } from './standard-section';


interface Props {
  anchor: string;
  title: string;
  attention: string;
}


export const CustomersSection: React.FunctionComponent<Props> = (props) => {
  return (
    <StandardSection title={props.title}
                     attention={props.attention}
                     anchor={props.anchor}
                     backgroundColor='primaryTint'>
    </StandardSection>
  );
};
