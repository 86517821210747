import { css } from '@emotion/core';
import * as React from 'react';
import { howItWorksStepsQuery } from '../hooks/how-it-works-steps.query';
import styled from '../styles/styled';
import { StandardSection } from './standard-section';


export interface StepDetail {
  icon: {
    publicURL: string;
    internal: {
      content: string;
      mediaType: string;
    };
  };
  heading: string;
  body: string;
}


export interface Step {
  id: string;
  idx: number;
  banner: {
    image: {
      publicURL: string;
    };
    position: 'left' | 'right';
  };
  title: string;
  description: string;
  details: StepDetail[];
  connector: {
    publicURL: string;
  };
}


interface Props {
  anchor: string;
  title: string;
  attention: string;
}


export const HowItWorksSection: React.FunctionComponent<Props> = (props) => {
  const steps = howItWorksStepsQuery();
  return (
    <StandardSection title={props.title}
                     attention={props.attention}
                     anchor={props.anchor}
                     backgroundColor='primaryTint'>
      <div style={{ textAlign: 'center' }}>
        {createSteps(steps)}
      </div>
    </StandardSection>
  );
};


const Step = styled.div`
  display: flex;
  flex-direction: row;;
  flex-grow: 1;
  align-items: center;
  text-align: start;
  position: relative;
  color: ${(props) => props.theme.colors.secondary.dark};

  & img {
    position: relative;
    margin: 10px 0;

    &.right {
      right: -125px;
    }
    &.left {
      left: -30px;
    }
  }
`;

const StepCard = styled.div`
  padding: 0 calc(${(props) => props.theme.sizes.desktop.content.margin} * 2);
  max-width: 50%;
  box-sizing: border-box;
`;

const emOrange = (theme) => css`
  & em {
    font-style: normal;
    color: ${theme.colors.primary.normal};
  }
`;

const StepTitle = styled.h2`
  ${(props) => emOrange(props.theme)};
`;

const StepDesc = styled.div`
  ${(props) => emOrange(props.theme)};
`;

const StepDetails = styled.div`
  ${(props) => emOrange(props.theme)};
  margin-top: 2.5rem;
`;

const StepDetail = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  margin-top: 2.5rem;

  & h4 {
    margin-bottom: .5rem;
  }

`;

const StepDetailIcon = styled.div`
  margin: 0;
  box-sizing: border-box;;
  border: solid 1.5px ${(props) => props.theme.colors.primary.normal};
  width: 65px;
  height: 65px;
  padding: 10.5px;
  border-radius: 100%;

  & svg {
    width: 40px;
    height: 40px;
    fill: ${(props) => props.theme.colors.primary.normal};
  }
`;

const StepDetailBody = styled.div`
  padding-left: 1rem;
  font-size: smaller;
`;

const StepConnector = styled.img`
  margin: 0 auto -100px auto;
`;

function createSteps(steps: Step[]) {

  const results = [];

  for (const step of steps) {
    results.push(createStep(step));
    if (step.connector) {
      results.push(<StepConnector key={step.id + '-connector'}
                                  src={step.connector.publicURL} alt=''/>);
    }
  }

  return results;
}

function createStep(step: Step) {
  if (step.banner.position === 'left') {
    return (
      <Step key={step.id}>
        <img src={step.banner.image.publicURL} alt='' className='left'/>
        {createStepCard(step)}
      </Step>
    );
  } else {
    return (
      <Step key={step.id}>
        {createStepCard(step)}
        <img src={step.banner.image.publicURL} alt='' className='right'/>
      </Step>
    );
  }
}

function createStepCard(step: Step) {
  return (
    <StepCard>
      <StepTitle dangerouslySetInnerHTML={{ __html: step.title }}/>
      <StepDesc dangerouslySetInnerHTML={{ __html: step.description }}/>
      {step.details && <StepDetails>{createStepDetails(step.details)}</StepDetails>}
    </StepCard>
  );
}

function createStepDetails(details: StepDetail[]) {
  const results = [];

  for (let idx = 0; idx < details.length; ++idx) {
    const detail = details[idx];
    results.push(
      <StepDetail key={idx}>
        {createStepDetailsIcon(detail.icon)}
        <StepDetailBody>
          <h4>{detail.heading}</h4>
          <div dangerouslySetInnerHTML={{ __html: detail.body }}/>
        </StepDetailBody>
      </StepDetail>,
    );
  }

  return results;
}


function createStepDetailsIcon(icon: StepDetail['icon']) {
  if (icon.internal.mediaType === 'image/svg+xml') {
    return <StepDetailIcon dangerouslySetInnerHTML={{ __html: icon.internal.content }}/>;
  } else {
    return <img src={icon.publicURL} alt=''/>;
  }
}
