import * as React from 'react';
import { featuresQuery } from '../hooks/features.query';
import styled from '../styles/styled';
import { ColoredPrimary } from '../styles/utils';
import { FeatureBox } from './feature-box';
import { StandardSection } from './standard-section';


export interface Feature {
  id: string;
  icon: {
    publicURL: string;
    internal: {
      content?: string;
    }
  };
  title: string;
  description: string;
}


interface Props {
  anchor: string;
  title: string;
  attention: string;
}


export const FeatureSection: React.FunctionComponent<Props> = (props) => {
  const features = featuresQuery();
  return (
    <StandardSection title={props.title}
                     attention={props.attention}
                     anchor={props.anchor}
                     backgroundColor='secondaryTint'>
      <FeatureBoxes>
        {features.map((feature) =>
          <FeatureBox key={feature.id}
                      icon={feature.icon}
                      title={feature.title}
                      description={feature.description}/>,
        )}
      </FeatureBoxes>
    </StandardSection>
  );
};


const FeatureBoxes = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;

  & div {
    max-width: 350px;
    margin: 20px;
  }
`;
