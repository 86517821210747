import * as React from 'react';
import featureIconBg from '../images/features/bg.png';
import styled from '../styles/styled';
import { Feature } from './feature-section';


interface Props {
  icon: Feature['icon'];
}


export const FeatureIcon: React.FunctionComponent<Props> = (props) => {
  return (
    <Container dangerouslySetInnerHTML={{__html: props.icon.internal.content}}/>
  );
};


const Container = styled.div`
  background-image: url("${featureIconBg}");
  background-position: top left;
  background-repeat: no-repeat;
  padding: 15px 10px 10px 10px;
  margin-top: 2rem;

  & svg {
    width: 50px;
    height: 50px;
    margin: 0;
    fill: ${(props) => props.theme.colors.secondary.normal};
  }
`;
