import * as React from 'react';
import { solutionsQuery } from '../hooks/solutions.query';
import * as LeftIcon from '../images/left.inline.svg';
import * as RightIcon from '../images/right.inline.svg';
import styled from '../styles/styled';
import { StandardSection } from './standard-section';

export interface Solution {
  id: string;
  heading: string;
  body: string;
  banner: {
    publicURL: string;
  };
}

interface Props {
  anchor: string;
  title: string;
  attention: string;
}


export const SolutionsSection: React.FunctionComponent<Props> = (props) => {
  const solutions = solutionsQuery();
  return (
    <StandardSection title={props.title}
                     attention={props.attention}
                     anchor={props.anchor}
                     backgroundColor='secondaryTint'>
      <SolutionCards solutions={solutions}/>
    </StandardSection>
  );
};



class SolutionCards extends React.Component<{ solutions: Solution[] }> {

  private readonly container: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.container = React.createRef();
  }

  public componentDidMount(): void {
    const container = this.container.current;
    if (!container) {
      return;
    }

    container.style.scrollBehavior = 'smooth';
  }

  public render() {
    return (
      <div>
        <SolutionCardsContainer ref={this.container}>
          {createSolutions(this.props.solutions)}
        </SolutionCardsContainer>
        <ScrollButton className='left' onClick={() => this.handleMove(-1)}>
          <LeftIcon/>
        </ScrollButton>
        <ScrollButton className='right' onClick={() => this.handleMove(1)}>
          <RightIcon/>
        </ScrollButton>
      </div>
    );
  }

  private handleMove(offset: number) {
    const container = this.container.current;
    if (!container) {
      return;
    }

    const idx = (container.offsetLeft / 520) + offset;
    container.scrollTo({ left: idx * 520 });
  }

}


const SolutionCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow: scroll;
`;

function createSolutions(solutions: Solution[]) {

  const items = [];

  for (const solution of solutions) {
    items.push(<SolutionCard key={solution.id}
                             id={solution.id}
                             banner={solution.banner}
                             heading={solution.heading}
                             body={solution.body}/>);
  }

  return items;
}


const SolutionCard: React.FunctionComponent<Solution> = (props) => {
  return (
    <SolutionCardPadding>
      <SolutionCardContainer>
        <SolutionBanner src={props.banner.publicURL} alt=''/>
        <SolutionCardDetails>
          <h3>{props.heading}</h3>
          <div dangerouslySetInnerHTML={{__html: props.body}}/>
        </SolutionCardDetails>
      </SolutionCardContainer>
    </SolutionCardPadding>
  );
};

const SolutionCardPadding = styled.div`
  padding: 2rem;
  &:not(:last-child) {
    padding-right: 0;
  }
`;

const SolutionCardContainer = styled.div`
  flex-shrink: 0;
  display: inline-block;
  width: 520px;
  height: 100%;
  background-color: ${(props) => props.theme.colors.primaryTint.dark};
  color: ${(props) => props.theme.colors.primaryTint.text};
  border-radius: 6px;
  box-shadow: 0 0 37px -10px rgba(0,0,0,0.5);
  overflow: hidden;

  h3 {
    color: ${(props) => props.theme.colors.primary.normal};
  }
`;

const SolutionBanner = styled.img`
  width: 100%;
  height: 350px;
  object-fit: cover;
`;

const SolutionCardDetails = styled.div`
  padding: 1.5rem;
`;

const ScrollButton = styled.div`
  position: absolute;
  top: 50%;
  cursor: pointer;

  & svg {
    width: 50px;
    height: 50px;
    fill: ${(props) => props.theme.colors.secondary.normal};
    opacity: .3;

    &:hover {
      opacity: 1;
    }
  }

  &.left {
    left: -50px;
  }

  &.right {
    right: -50px;
  }
`;
