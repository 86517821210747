import * as React from 'react';
import HeroSplitEdge from '../images/hero-split-edge.inline.svg';
import styled from '../styles/styled';
import { overlay } from '../styles/utils';
import { ItemIndicator } from './item-indicator';
import { PrimaryButton } from './primary-button';


export interface Hero {
  heading: string;
  subHeading: string;
  learn: {
    title: string;
    target: string;
  };
  image: {
    publicURL: string;
  };
}


interface Props {
  heroes: Hero[];
  headline: string;
  onHeroChange?: (visibleIdx: number) => void;
}


interface State {
  visibleIdx: number;
  nextVisibleIdx?: number;
  prevVisibleIdx?: number;
  paused: boolean;
}


export class HeroCarousel extends React.Component<Props, State> {

  private autoFlipHandle?: number;

  constructor(props) {
    super(props);
    this.state = {
      visibleIdx: 0,
      paused: false,
      nextVisibleIdx: undefined,
      prevVisibleIdx: undefined,
    };
  }

  public componentDidMount(): void {
    this.autoFlipHandle = window.setInterval(() => {
      if (!this.state.paused) {
        this.startNext();
      }
    }, 6500);
  }

  public componentWillUnmount(): void {
    clearInterval(this.autoFlipHandle);
  }

  public render() {
    const hero = this.props.heroes[this.state.visibleIdx];
    return (
      <Container className={this.currentClassName() + ' heroes'}
                 onTransitionEnd={(e) => this.flipNext(e)}>
        <BannerLayer>
          <BannerFill/>
          <BannerEdge>
            <StyleEdgePath css={{ visibility: 'hidden' }}/>
            <BannerWrapper>
              <Banner src={hero.image.publicURL}/>
            </BannerWrapper>
          </BannerEdge>
        </BannerLayer>
        <StyleLayer>
          <StyleFill/>
          <StyleEdgePath/>
        </StyleLayer>
        <Heading dangerouslySetInnerHTML={{__html: this.props.headline}}/>
        <MessageLayer>
          <MessageHeading>{hero.heading}</MessageHeading>
          <MessageSubHeading dangerouslySetInnerHTML={{ __html: hero.subHeading }}/>
          <PrimaryButton title={hero.learn.title}
                         href={hero.learn.target}/>
        </MessageLayer>
        <Indicator onMouseEnter={() => this.pause(true)}
                   onMouseLeave={() => this.pause(false)}>
          <ItemIndicator count={this.props.heroes.length}
                         active={this.state.visibleIdx}
                         hoverParent='.heroes'
                         onSelect={(idx) => this.startNext(idx)}/>
        </Indicator>
      </Container>
    );
  }

  private startNext(next?: number) {
    this.setState((state, props) => ({
      nextVisibleIdx: next !== undefined ? next : ((state.visibleIdx + 1) % props.heroes.length),
      prevVisibleIdx: undefined,
    }));
  }

  private flipNext(e: React.TransitionEvent) {
    if (e.propertyName === 'opacity') {
      return;
    }

    this.setState((state) => {
      if (this.state.nextVisibleIdx !== undefined) {
        return {
          visibleIdx: state.nextVisibleIdx,
          prevVisibleIdx: state.visibleIdx,
          nextVisibleIdx: undefined,
        };
      } else {
        if (this.props.onHeroChange) {
          this.props.onHeroChange(state.visibleIdx);
        }
        return {
          visibleIdx: state.visibleIdx,
          prevVisibleIdx: undefined,
          nextVisibleIdx: undefined,
        };
      }
    });
  }

  private pause(paused: boolean) {
    this.setState({ paused });
  }

  private currentClassName(): string | undefined {
    if (this.state.nextVisibleIdx !== undefined) {
      return 'exit';
    } else if (this.state.prevVisibleIdx !== undefined) {
      return 'enter';
    }
    return undefined;
  }

}

const Heading = styled.div`
  position: absolute;
  top: ${(props) => props.theme.sizes.desktop.header.height};
  padding-top: 82px;
  color: rgba(255,255,255,0.5);
  max-width: 60%;
`;

const Container = styled.div`
  height: ${(props) => props.theme.sizes.desktop.hero.height};
  width: ${(props) => props.theme.sizes.desktop.width};
  padding: ${(props) => props.theme.sizes.desktop.content.margin};
  margin: auto;
  position: relative;
`;
Container.displayName = 'Container';

const StyleLayer = styled.div`
  ${overlay};
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;
StyleLayer.displayName = 'StyleLayer';

const StyleFill = styled.div`
  flex-grow: 1;
  background-color: ${(props) => props.theme.colors.secondary.normal};
`;
StyleFill.displayName = 'StyleFill';

const StyleEdgePath = styled(HeroSplitEdge)`
  height: 100%;
  width: auto;
  position: relative;
  left: -1px;
  & path {
    fill: ${((props) => props.theme.colors.secondary.normal)};
  }
`;
StyleEdgePath.displayName = 'StyleEdgePath';

const BannerLayer = styled.div`
  ${overlay};
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background: none;
  opacity: 1;
  transition: opacity 200ms ease-out, right 400ms ease-out;
  transition-delay: 300ms;

  .exit & {
    right: 100%;
    opacity: 0;
    transition-timing-function: ease-in;
    transition-delay: 0ms;
  }

  .enter & {
    right: -100%;
    opacity: 0;
    transition-duration: 100ms;
    transition-delay: 0s, 0s;
  }
`;
BannerLayer.displayName = 'BannerLayer';

const BannerFill = styled.div`
  flex-grow: 1;
`;
BannerFill.displayName = 'BannerFill';

const BannerEdge = styled.div`
  height: 100%;
  width: auto;
  background-color: transparent;
  overflow: visible;
  position: relative;
`;
BannerEdge.displayName = 'BannerEdge';

const BannerWrapper = styled.div`
  ${overlay};
  display: flex;
  flex-direction: row;
  align-items: center;
`;
BannerWrapper.displayName = 'BannerWrapper';

const Banner = styled.img`
  max-width: none;
  margin: 0;
  padding: 0 0 0 7%;
`;
Banner.displayName = 'Banner';

const MessageLayer = styled.div`
  ${overlay};
  position: relative;
  color: ${(props) => props.theme.colors.secondary.text};
  transition: opacity 350ms ease-out, left 700ms ease-out;
  padding-top: 20%;
  padding-right: 50%;

  .exit & {
    left: -100%;
    opacity: 0;
    transition-timing-function: ease-in;
  }

  .enter & {
    left: 100%;
    opacity: 0;
    transition-duration: 100ms;
  }
`;
MessageLayer.displayName = 'MessageLayer';

const MessageHeading = styled.h1`
  font-weight: bold;
  font-size: 48px;
  line-height: 1.35em;
`;
MessageHeading.displayName = 'MessageHeading';

const MessageSubHeading = styled.div`
  margin-top: 2rem;
  margin-bottom: 3.5rem;
  font-size: 20px;
`;
MessageSubHeading.displayName = 'MessageSubHeading';

const Indicator = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding-bottom: 30px;
`;
