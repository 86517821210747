import * as React from 'react';
import * as ReactModal from 'react-modal';
import { ContactUsSection } from '../components/contact-us-section';
import { CustomersSection } from '../components/customers-section';
import { FeatureSection } from '../components/feature-section';
import { HeroSection } from '../components/hero-section';
import { HowItWorksSection } from '../components/how-it-works-section';
import { Layout } from '../components/layout';
import { SolutionsSection } from '../components/solutions-section';
import { sectionsQuery } from '../hooks/sections.query';

ReactModal.setAppElement('#___gatsby');


export interface Section {
    id: string;
    name: string;
    title: string;
    attention: string;
}

export default () => {
    const sections = sectionsQuery();
    return (
      <Layout title='Home'>
          {createSections(sections)}
      </Layout>
    );
};


function createSections(sections: Section[]) {
    const results = [];

    for (const section of sections) {
        switch (section.name) {
            case 'heroes':
                results.push(<HeroSection key={section.id}
                                          headline={section.attention}/>);
                break;
            case 'features':
                results.push(<FeatureSection key={section.id}
                                             anchor={section.name}
                                             title={section.title}
                                             attention={section.attention}/>);
                break;
            case 'how-it-works':
                results.push(<HowItWorksSection key={section.id}
                                                anchor={section.name}
                                                title={section.title}
                                                attention={section.attention}/>);
                break;
            case 'solutions':
                results.push(<SolutionsSection key={section.id}
                                               anchor={section.name}
                                               title={section.title}
                                               attention={section.attention}/>);
                break;
            case 'customers':
                results.push(<CustomersSection key={section.id}
                                               anchor={section.name}
                                               title={section.title}
                                               attention={section.attention}/>);
                break;
            case 'contact-us':
                results.push(<ContactUsSection key={section.id}
                                               anchor={section.name}
                                               title={section.title}
                                               attention={section.attention}/>);
                break;
        }
    }

    return results;
}
