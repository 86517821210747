import { faEnvelope, faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { contactQuery } from '../hooks/contact.query';
import contactUsBg from '../images/contact-bg.png';
import styled from '../styles/styled';
import { overlay } from '../styles/utils';
import { ContactUsForm } from './contact-us-form';
import { StandardSection } from './standard-section';


export interface ContactMethod {
  type: 'address' | 'phone' | 'email';
  display: string;
  value?: string;
}


export interface ContactLocation {
  name: string;
  methods: ContactMethod[];
}


export interface Contact {
  locations: ContactLocation[];
}


interface Props {
  anchor: string;
  title: string;
  attention: string;
}


export const ContactUsSection: React.FunctionComponent<Props> = (props) => {
  const data = contactQuery();
  return (
    <StandardSection title={props.title}
                     attention={props.attention}
                     anchor={props.anchor}
                     background={<FancyBg/>}
                     minHeight={'calc(100vh - 100px)'}
                     backgroundColor='primary'>
      <Container>
        <ContactUsForm />
        {createDetailsLocations(data.locations)}
      </Container>
    </StandardSection>
  );
};

function createDetailsLocations(locations: ContactLocation[]) {
  const results = [];

  for (const location of locations) {
    results.push(
      <Location key={`location:${location.name}`}>
        <h4>{location.name}</h4>
        {createDetailLocationMethods(location.methods)}
      </Location>,
    );
  }

  return results;
}

function createDetailLocationMethods(methods: ContactMethod[]) {
  const results = [];

  for (const method of methods) {
    results.push(
      <Method key={`method:${method.value || method.display}`}>
        {createDetailMethodIcon(method)}
        {method.value && <a href={method.value}>{method.display}</a>}
        {!method.value && <div>{method.display}</div>}
      </Method>,
    );
  }

  return results;
}

function createDetailMethodIcon(method: ContactMethod) {
  switch (method.type) {
    case 'address':
      return <FontAwesomeIcon icon={faMapMarkerAlt}/>;
    case 'phone':
      return <FontAwesomeIcon icon={faPhone}/>;
    case 'email':
      return <FontAwesomeIcon icon={faEnvelope}/>;
  }
}


const FancyBg = styled.div`
  ${overlay};
  background-image: url("${contactUsBg}");
  background-position: top center;
`;


const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  color: ${(props) => props.theme.colors.primary.text};
  margin-top: 3rem;
  position: relative;
`;

const Form = styled.form`
  width: 50%;

  & > label {
    margin-top: 1.5rem;
    width: 90%;
    font-size: 1.5rem;

    & > textarea {
      height: 5em;
    }
  }

  & a {
    margin-top: 2rem;
    background: white;
    color: ${(props) => props.theme.colors.primary.normal};
  }
`;

const Location = styled.div`
  font-size: larger;

  & a {
    color: white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Method = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  align-items: center;
  margin-top: 1.5rem;

  & svg {
    color: ${(props) => props.theme.colors.primary.normal};
    background-color: ${(props) => props.theme.colors.primary.text};
    margin-right: 2rem;
    padding: 6px;
    width: 30px !important;
    height: 30px !important;
    border-radius: 30px;
    box-sizing: border-box;
  }
`;
