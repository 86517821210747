import { graphql, useStaticQuery } from 'gatsby';
import { Contact } from '../components/contact-us-section';
import { Feature } from '../components/feature-section';
import { Solution } from '../components/solutions-section';


export const solutionsQuery = (): Solution[] => {
  const { allSolutionsYaml } = useStaticQuery(
    graphql`
        query {
          allSolutionsYaml {
            nodes {
              id
              heading
              body
              banner {
                publicURL
              }
            }
          }
        }
      `,
  );

  return allSolutionsYaml.nodes;
};
