import * as React from 'react';
import { heroesQuery } from '../hooks/heroes.query';
import styled from '../styles/styled';
import { overlay } from '../styles/utils';
import { HeroCarousel } from './hero-carousel';


interface Props {
  headline: string;
}

export const HeroSection: React.FunctionComponent<Props> = (props) => {
  const heroes = heroesQuery();
  return (
    <Container>
      <ContainerBg>
        <div className='left'/>
        <div className='right'/>
      </ContainerBg>
      <HeroCarousel heroes={heroes} headline={props.headline}/>
    </Container>
  );
};

const Container = styled.section`
  position: relative;
  left: 0;
  right: 0;
  margin: auto;
  overflow: hidden;
`;
Container.displayName = 'Container';

const ContainerBg = styled.div`
  ${overlay};
  display: flex;
  flex-direction: row;
  align-items: stretch;

  & .left {
    flex-grow: 1;
    background-color: ${(props) => props.theme.colors.secondary.normal};
  }

  & .right {
    flex-grow: 1;
    background-color: ${(props) => props.theme.colors.offWhite.normal};
  }
`;
ContainerBg.displayName = 'ContainerBg';


