import { graphql, useStaticQuery } from 'gatsby';
import { Contact } from '../components/contact-us-section';
import { Feature } from '../components/feature-section';


export const contactQuery = (): Contact => {
  const { allDataYaml } = useStaticQuery(
    graphql`
        query {
          allDataYaml(limit: 1) {
            nodes {
              contact {
                locations {
                  methods {
                    type
                    display
                    value
                  }
                  name
                }
              }
            }
          }
        }
      `,
  );

  return allDataYaml.nodes[0].contact;
};
