import * as React from 'react';
import { ChangeEvent, FormEvent } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import styled from '../styles/styled';
import { LabeledInput } from './labeled-input';
import { PrimaryButton } from './primary-button';


interface State {
  name: string;
  email: string;
  phone: string;
  message: string;
  statusMessage: string;
}


export class ContactUsForm extends React.Component<{}, State> {

  private readonly form: React.RefObject<HTMLFormElement>;
  private readonly recaptcha: React.RefObject<ReCAPTCHA>;

  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.recaptcha = React.createRef();
    this.state = {
      name: '',
      email: '',
      phone: '',
      message: '',
      statusMessage: '',
    };
  }

  public render() {
    return (
      <Form ref={this.form}
            name='contact-us'
            method='POST'
            data-netlify={true}
            data-netlify-honeypot='red-handed'
            onSubmit={this.handleSubmit}>
        <input type='hidden' name='red-handed'/>
        <LabeledInput type='text' name='name' placeholder='Name' color='white'
                      value={this.state.name}
                      onChange={this.handleChange}
                      required={true}/>
        <LabeledInput type='email' name='email' placeholder='E-mail' color='white'
                      value={this.state.email}
                      onChange={this.handleChange}
                      required={true}/>
        <LabeledInput type='phone' name='phone' placeholder='Phone' color='white'
                      value={this.state.phone}
                      onChange={this.handleChange}/>
        <LabeledInput type='textarea' name='message' placeholder='Message' color='white'
                      value={this.state.message}
                      onChange={this.handleChange}
                      required={true}/>
        {this.state.statusMessage &&
        <p className='status'>{this.state.statusMessage}</p>
        }
        <PrimaryButton title='Send' type='submit'/>
      </Form>
    );
  }

  private readonly handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const form = event.target as HTMLFormElement;

    try {

      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': form.getAttribute('name'),
          ...this.state,
        }),
      });

      this.setState({
        name: '',
        email: '',
        phone: '',
        message: '',
        statusMessage: `Message received, we'll respond as soon as we can!`,
      });

    } catch (error) {
      this.setState({
        statusMessage: `Contact submission failed: ${error}`,
      });
    }
  }

  private readonly handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const state = {};
    state[event.target.name] = event.target.value;
    this.setState(state);
  }

}


const Form = styled.form`
  width: 50%;

  & > label {
    margin-top: 1.5rem;
    font-size: 1.5rem;
    width: 90%;

    & > textarea {
      height: 5em;
    }
  }

  p.status {
    font-size: smaller;
    margin: 1rem 0 0 0;
  }

  p.reCAPTCHA {
    margin-top: 1rem;
    font-size: smaller;

    a {
      background: none;
      color: ${(props) => props.theme.colors.secondary.normal};
      white-space: nowrap;
    }
  }

  .grecaptcha-badge { visibility: hidden; }
`;

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}
