import * as React from 'react';
import styled from '../styles/styled';


interface Props {
  count: number;
  active: number;
  hoverParent?: string;
  children?: never;

  onSelect(index: number);
}


interface DashProps {
  active: boolean;
  hoverParent?: string;
}


const Dash = styled.li<DashProps>`
  list-style: none;
  display: block;
  position: relative;
  width: 20px;
  height: 6px;
  margin: 0;
  box-sizing: content-box;
  border: 4px transparent solid;
  cursor: pointer;

  opacity: ${(props) => !props.active ? 0.3 : 0.5};
   ${(props) => props.hoverParent || 'ul'}:hover & {
    opacity: ${(props) => !props.active ? 0.6 : 0.9};
  };

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    background-color: white;
    cursor: pointer;
  }
`;


interface DashesProps {
  hoverParent?: string;
}


const Dashes = styled.ul<DashesProps>`
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(255,255,255,0.2);
  margin: 0;
  border-radius: 4px;
  padding: 2px 4px;

  ${(props) => props.hoverParent ? `${props.hoverParent}:hover &` : '&:hover'} {
    background-color: rgba(255,255,255,0.3);
  }
`;


export class ItemIndicator extends React.Component<Props> {

  public render() {
    return (
      <Dashes hoverParent={this.props.hoverParent}>{this.createDashes()}</Dashes>);
  }

  private createDashes() {
    const dashes = [];

    for (let idx = 0; idx < this.props.count; ++idx) {
      dashes.push((<Dash key={idx + 1}
                         active={idx === this.props.active}
                         onClick={() => this.props.onSelect && this.props.onSelect(idx)}
                         hoverParent={this.props.hoverParent}/>));
    }

    return dashes;
  }
}
