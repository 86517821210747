import { graphql } from 'gatsby';
import { useStaticQuery } from 'gatsby';
import { Hero } from '../components/hero-carousel';


export const heroesQuery = (): Hero[] => {
  const { allHeroesYaml } = useStaticQuery(
    graphql`
        query {
          allHeroesYaml {
            edges {
              node {
                heading
                subHeading
                learn {
                  target
                  title
                }
                image {
                  publicURL
                }
              }
            }
          }
        }
      `,
  );

  return allHeroesYaml.edges.map((edge) => edge.node);
};
