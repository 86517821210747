import * as React from 'react';
import sectionHeaderBg from '../images/dots.svg';
import styled from '../styles/styled';
import { THEME } from '../styles/theme';


interface Props {
  anchor: string;
  title?: string;
  attention?: any;
  background?: any;
  backgroundColor?: keyof typeof THEME.colors;
  minHeight?: string;
  children?: any;
}


export class StandardSection extends React.Component<Props> {

  public static defaultProps = {
    backgroundColor: 'primary',
  };

  public render() {
    return (
      <Container id={this.props.anchor}
                 backgroundColor={this.props.backgroundColor}
                 headerBackgroundColor={this.props.backgroundColor.toString().endsWith('Tint') ? 'primary' : 'white'}
                 minHeight={this.props.minHeight}>
        {this.props.background}
        <Content>
          {this.props.title &&
          <h6>{this.props.title}</h6>
          }
          {this.props.attention &&
          <h2 dangerouslySetInnerHTML={{__html: this.props.attention}}/>
          }
          {this.props.children}
        </Content>
      </Container>
    );
  }
}


interface ContainerProps {
  backgroundColor: Props['backgroundColor'];
  headerBackgroundColor: Props['backgroundColor'];
  minHeight?: string;
}


const Container = styled.section<ContainerProps>`
  position: relative;
  left: 0;
  right: 0;
  margin: auto;
  overflow: hidden;
  padding-top: 150px;
  background-color: ${(props) => props.theme.colors[props.backgroundColor].normal};
  min-height: ${(props) => props.minHeight || 'none'};

  & h2 {
    margin-top: 1rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors[props.backgroundColor].text};

    em {
      color: ${(props) => props.theme.colors.primary.normal};
      font-style: normal;
    }
  }

  & h6 {
    position: absolute;
    left: 0;
    top: 0;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors[props.backgroundColor].text};

    &::before {
      content: '';
      display: block;
      position: absolute;
      background: linear-gradient(90deg, ${(props) => props.theme.colors[props.headerBackgroundColor].dark} 0%,
                                         ${(props) => props.theme.colors[props.headerBackgroundColor].normal} 100%);
      mask-image: url("${sectionHeaderBg}");
      width: 93px;
      height: 93px;
      left: -65px;
      top: -50px;
      opacity: .4;
    }
  }
`;
Container.displayName = 'Container';


const Content = styled.div`
  width: ${(props) => props.theme.sizes.desktop.width};
  padding: ${(props) => props.theme.sizes.desktop.content.margin};
  margin: auto;
  position: relative;
`;
Container.displayName = 'Content';
